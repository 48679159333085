const RoutesEnum = {
  // 入口
  MAIN: 'Main',
  // 首页
  HOME: 'Home',
  // 个人简介
  PERSONAL: 'Personal',
  // 爱好
  HOBBY: 'Hobby',
  // 小游戏
  GAMES: 'Games',
  // 关于博客
  BLOGABOUT: 'BlogAbout',
  // 友链
  FRIENDLYLINK: 'FriendlyLink',

};
export default RoutesEnum;
